import { Injectable } from '@angular/core';
import { CustomerListFacade } from '../customer';
import { UserFacade } from '../user';

@Injectable({
    providedIn: 'root',
})
export class CleanupService {
    constructor(private userFacade: UserFacade, private customerListFacade: CustomerListFacade) {}
    cleanup(): void {
        this.userFacade.clear();
        this.customerListFacade.clear();
    }
}
